const uz = {
  Продолжить: 'Davom etish',
  'Arizangizni yuboring': 'Arizangizni yuboring',
  Подтвердить: 'Tasdiqlash',
  'Введите код из СМС': 'SMS-dan kelgan kodni kiriting',
  'Каталог ЖК': 'Yangi binolar',
  'Карта новостроек': 'Xarita',
  Квартиры: 'Kvartiralar',
  Застройщик: 'Quruvchi',
  Застройщики: 'Quruvchilar',
  Журнал: 'Jurnal',
  Изменить: "O'zgartirish",
  Меню: 'Menyu',
  'Войти или зарегистрироваться': "Kirish yoki ro'yxatdan o'tish",
  Выйти: 'Chiqish',
  Избранное: 'Sevimli',
  'Ваш город': 'Sizning shahringiz',
  'Войти или создать личный кабинет': 'Kirish yoki shaxsiy kabinet yaratish',
  'Номер телефона': 'Telefon raqami',
  'Загрузите сейчас! Не пропустите акции и скидки': "Hoziroq yuklab oling! Chegirmalarni o'tkazib yubormang",
  'Наведите камеру на QR-код, чтобы скачать': "Yuklab olish uchun kamerani QR kodiga yo'naltiring",
  'Для застройщиков': 'Quruvchilar uchun',
  'Колл-центр': "Qo'ng'iroq markazi",
  'Другие ЖК от застройщика': 'Quruvchining boshqa TJMlari',
  Tugash_muddati: 'Topshirish muddati',
  Ipoteka: "Bo'lib to'lash",
  Uy_sinfi: 'Uy-joy sinfi',
  Xonadonlar: 'Xonadonlar',
  Infrastruktura: 'Infrastruktura',
  Genplan: 'Genplan',
  Haqida: 'TJM haqida',
  Ulashish: 'Ulashish',
  Saqlanganlar: 'Sevimli',
  ЖК: 'TJM',
  Ipoteka_text: 'ajratilmaydi',
  Quruvchi: 'Quruvchi',
  'Узнать цену': 'Narxni bilish',
  'Nusxa olish': 'Nusxa olish',
  'Nusxa olindi': 'Nusxa olindi',
  title: 'Toshkentda yangi binolar - Yangi binodan kvartira sotib oling',
  description:
    'Toshkent shahridagi yangi turar-joy majmualaridan kvartira sotib oling. Eng yaxshi narxlar va takliflar Uysot.uz saytida.',
  Договорная: 'Kelishuvli',
  'Показать телефон': "Telefonni ko'rsatish",
  'Перезвоните мне': "Menga qo'ng'iroq qiling",
  'Ushbu sahifa topilmadi!': 'Ushbu sahifa topilmadi!',
  'malumot topilmadi': "Ma'lumot topilmadi",
  'Sahifa mavjud emas': 'Kechirasiz, siz qidirayotgan sahifa mavjud emas yoki ko‘chirilgan.',
  'Bosh sahifaga qaytish': 'Bosh sahifaga qaytish',
  'Yuklab olish': 'Yuklab olish',
  Saqlash: 'Saqlash',
  Til: 'Til',
  Valyuta: 'Valyuta',
  expo_title: 'TABRIKLAYMIZ! SIZ UYSOT EXPO ISHTIROKCHISIGA AYLANDINGIZ!',
  expo_ticket: 'Biletni yuklab oling va 2-3 mart kunlari bo’lib o’tadigan “UYSOT EXPO”da ishtirok eting.',
  expo_body: 'SIZDA USHBU SOVG’ALARNI YUTISH IMKONI BOR',
  Dubayga_chipta: 'Dubayga chipta',
  'Umra ziyoratiga yo’llanma': 'Umra ziyoratiga yo’llanma',
  '50 dan ortiq boshqa sovg’alar': '50 dan ortiq boshqa sovg’alar',
  expo_footer:
    'Qoidalar: Ushbu yutuqli o’yinda ishtirok etish uchun 2-3 mart kunlari UYSOT EXPOga tashrif buyurishingiz lozim',
  dynamic_empty_title: 'Aktual ma’lumotlar mavjud emas!',
  dynamic_empty_descr:
    'Kechirasiz, siz qidirayotgan TJM bo’yicha aktual ma’lumotlar mavjud emas. Tez orada shakllanadi.',
  Tez_tez_beriladigan_savollar: 'Tez-tez beriladigan savollar',
  Eng_kop_beriladigan_savollar: 'Eng ko’p beriladigan savollar',
  TOP: 'TOP',
  Toshkentdagi_yangi_uylar: 'Toshkent shahridan yangi binolar',
  Toshkentdagi_yangi_kvartiralar: 'Toshkent shahridan kvartiralar',
  Butun_Ozbekiston_boylab_yangi_uylar: 'Butun O‘zbekiston bo‘ylab yangi binolar',
  Butun_Ozbekiston_boylab_kvartiralar: "Butun O'zbekiston bo'ylab kvartiralar",
  takliflar: ' O’zbekistondagi yangi binolardan (TJM) kvartiralar',
  Batafsil: 'Batafsil',
  Hammasi: 'Barchasi',
  Qurilish_jarayoni: 'Qurilish jarayoni',
  notif_title: "Cheklangan takliflarga a'zo bo'ling",
  notif_text:
    "Faqat a'zolar uchun mo'ljallangan eksklyuziv chegirmalar va aksiyalardan birinchi bo'lib foydalangan holda xarid qiling.",
  notif_button: 'Obuna bo’lish',
  ishonchli: 'Ishonchli',
  tasdiqlangan: 'Tasdiqlangan',
  seo_text: `Bizning veb-saytimizda O'zbekistondagi har qanday ko'chmas mulklar turlarini topasiz. Kvartiralar va boshqa binolarning keng tanlovi mavjud. O'zbekistondagi yangi binolar haqida dolzarb ma'lumotlarni saytimizda chop etib boramiz. Katalogda quruvchilarning joriy narxlari ko'rsatilgan. Barcha obyektlar bo'yicha ma'lumotlarning mavjudligi, shu jumladan texnik xususiyatlar, kvartiralarning planirovkalari O'zbekistondan ko'chmas mulk sotib olishga yordam beradi. Bizning saytda kvartiralarning narxlari aktual holatda bo’ladi. <br /> <br />  <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> saytidan O'zbekistondagi eng arzon, ishonchli va sifatli yangi uylarni toping. Ijaraga turishdan charchadingizmi? Boshlang'ich to'lovsiz, bo’lib to’lashga yoki ipotekaga olinadigan yangi kvartiralar va uylar sizni kutmoqda. Bizning platformamizda karobka holatidagi uylardan tortib, to'liq remontli uylar, zamonaviy kvartiralar va tayyor holatdagi xonadonlargacha bo'lgan barcha turdagi takliflarni kashf eting. Yangi qurilgan uylar haqida eng so'nggi takliflarni o'rganib, orzuingizdagi uyni eng yaxshi va eng arzon narxlarda toping. <br /> <br /> <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> ijaradan voz kechish va o'zingizning uyingizga ega bo'lish yo'lingizdagi ishonchli sherik. Eng arzon narxlarda yangi uylarni kreditga yoki ipotekaga sotib olish imkoniyatlari bizning saytimizda.`,
  seo_title: 'O’zbekiston bo’ylab yangi uylar',
  document: 'hujjat',
  documents: 'Hujjatlar',
  tolov_summasi: 'To’lov summasi',
  Расположение: 'Joylashuv',
  Фильтр: 'Filtr',
  tolov_turi: "To'lov turi",
  'Журнал UYSOT': 'UYSOT jurnali',
  'Head title': "O'zbekistondagi barcha novostroykalar haqida blog: maqolalar, yangiliklar, maslahatlar, tahlillar",
  elon_joylash: 'Hurmatli quruvchi!',
  murojaat_qilish: "Biz bilan bog'laning",
  TJM_joylashtiring_xaridorlarni_jalb_qiling: 'Sifatli lidlar kerakmi?',
  builder_quiz_title: 'Yangi binolarni tezroq soting – bizning platformaga joylashtiring!',
  builder_quiz_text:
    'Platformamizga obyektlaringizni joylashtiring va ko‘proq xaridorlarni jalb qiling. Har bir bosqichda yordam va keng ko‘lamli auditoriya kutmoqda!',
  keyingisi: 'Keyingisi',
  ortga: 'Ortga',
  savol: 'savol',
  oxirgi_savol: 'Oxirgi savol',
  xonadoni_qayerdan_qidiramiz: 'Xonadoni qayerdan qidiryapsiz?',
  ariza_yuborish: 'Ariza yuborish',
  ming: 'ming',
  mln: 'mln',
  mlrd: 'mlrd',
  trln: 'trln',
  FISH: 'F.I.SH',
  tanlash_qiyinmi: 'Tanlash qiyinmi?',
  mos_uy: 'sizga mos keladigan xonadoni topishda yordam beradi.',
  xonalik: 'xonalik',
  siz_nechi_xonalik_kvartirani_qidiryapsiz: 'Siz necha xonalik kvartirani qidiryapsiz?',
  multi_select_rooms: 'Bir necha xil variant tanlashingiz mumkin',
  uy_joy_xarid_qilish_usuli: 'Uy-joy xarid qilish usuli',
  narx_oraligi: "Qaysi narx oralig‘ida bo'lishini istaysiz?",
  ism_telefon_raqam: 'Ismingiz va telefon raqamingizni yozib qoldiring!',
  ism_telefon_raqam_text: 'Bu bizga sizga qayta aloqa qilishimiz uchun zarur.',
  muvaffaqqiyatli: 'Muvaffaqqiyatli',
  siz_bilan_boglanamiz: 'Tayyor! Arizangiz jo‘natildi, tez orada bog‘lanamiz.',
  uysotdan_taklif: 'Bo‘sh vaqtingizni foydali o‘tkazing – Uysotdan tavsiyalar bor!',
  oylik_tolov_oraligi: "Oylik to‘lov qaysi narx oralig‘ida bo'lishini istaysiz?",
  boshlangich_tolov_oraligi: "Boshlang'ich to‘lov qaysi narx oralig‘ida bo'lishini istaysiz?",
  quiz_card_title: 'Xonadon izlash, topish va tanlashda qiynalyapsizmi?',
  quiz_card_text:
    'Uysot jamoasiga orzuingizdagi uyni tariflab bering. 20 000 dan ortiq xonadonlar ichidan sizga mos uyni topib beramiz',
  kvartiri: 'xonadonlar',
  novostroyki: 'yangi binolar',
  'Обратная связь': 'Qayta aloqa',
  Имя: 'Ism',
  see_more: "Ko'proq ko'rish",
  Рынок_новых_домов: 'Yangi uylar bozori',
  Скачать: 'Yuklab olish',
  'Ипотечный калькулятор': 'Ipoteka kalkulyatori',
  'Похожие новостройки': "O'xshash TJM lar",
  'Похожие квартиры': "O'xshash kvartiralar",
  'Похожие статьи': "O'xshash maqolalar",
  Cтатьи: 'Maqolalar',
  tozalash: 'Tozalash',
  mintaqa: 'Mintaqa tanlang',
  xonadon: 'Xonadon',
  maydon: 'Maydon',
  qavat: 'Qavat',
  holati: 'Holati',
  TJM: 'Turar-joy majmuasi',
  muddati: 'Muddati',
  'Chegirma va bonuslar': 'Chegirma va bonuslar',
  tolov: "To'lov",
  turi: 'Turi',
  'Boshlangich tolov': 'Boshlang‘ich to‘lov',
  'Перв. взнос': 'Bosh. to‘lov',
  'Oylik tolov': 'Oylik to‘lov',
  'Tolov muddati': 'To‘lov muddati',
  Qidirish: 'Qidirish',
  Найти: 'Qidirish',
  'Natijani koratish': "Natijani ko'rsatish",
  studiya: 'Studiya',
  xona: 'xona',
  Sinfi: 'Sinfi',
  'Foydalanish shartlari': 'Foydalanish shartlari',
  Yashirish: 'Yashirish',
  qurulish_jarayoni: 'Qurulish jarayoni',
  qurulish_hujjatlari: 'Qurulish hujjatlari',
  genplan: "Visual ko'rinish",
  badge_text: 'Uysot tamonidan tasdiqlangan',
  'Ijtimoiy tarmoqlar': 'Ijtimoiy tarmoqlar',
  'Profilingizga kiring': 'Profilingizga kiring',
  Kirish: 'Kirish yoki Ro‘yxatdan o‘tish',
  Chiqish: 'Chiqish',
  'Mintaqa tanlang': 'Mintaqa tanlang',
  ozbek_somi: 'O‘zbek so‘mi',
  aqsh_dollari: 'AQSH dollari',
  login: 'Kirish',
  'Смотреть фото': "Rasmni ko'rish",
  korinish_turi: 'Ko‘rinish turi',
  Xarita: 'Xarita',
  sum: 'so‘m',
  oy: 'oy',
  royxat: 'Ro‘yxat',
  tjm_royxat: "TJMlar ro'yxati",
  xonadonlar_royxati: "xonadonlar ro'yxati",
  qaynoq_takliflar: 'Eng qaynoq takliflar',
  "Ta'rifi": "Ta'rifi",
  Joylashuv: 'Joylashuv',
  "Ma'lumotlar": "Ma'lumotlar",
  Butun_Ozbekiston_boylab: "Butun O‘zbekiston bo'ylab",
  Bonuslar: 'Bonuslar',
  kun: 'kun',
  faollashtirish: 'Faollashtirish',
  qayta_yuklash: 'Qayta yuklash',
  aksiya_bonuslar: 'Aksiya va bonuslar',
  bonus_failed_text: 'Sizning shartnomangiz no faol. Iltimos shartnomani faolashtirib bizga qayta yuboring.',
  bonus_view_text: "Shartnomangiz ko'rib chiqilmoqda.",
  bonus_success_text: 'Shartnomangiz tasdiqlandi. Tez fursatda biz sizga aloqaga chiqamiz.',
  shartnomani_tasdiqlatish: 'Shartnomani tasdiqlatish',
  faol: 'Faol',
  Metro: 'Metro',
  'Metro yaqinidagi uylar': 'Metro yaqinidagi yangi binolar',
  'Bonuslar mavjud emas!': 'Bonuslar mavjud emas!',
  Показать_больше: "Ko'proq ko'rsatish",
  Показать_меньше: "Kamroq ko'rsatish",
  tashkent: 'Toshkent viloyati',
  'gorod-tashkent': 'Toshkent shahri',
  navoi: 'Navoiy viloyati',
  samarkand: 'Samarqand viloyati',
  bukhara: 'Buxoro viloyati',
  buxara: 'Buxoro viloyati',
  andijan: 'Andijon viloyati',
  surxandarya: 'Surxandaryo viloyati',
  djizak: 'Jizzax viloyati',
  xorezm: 'Xorazm viloyati',
  fergana: "Farg'ona viloyati",
  sirdarya: 'Sirdaryo viloyati',
  namangan: 'Namangan viloyati',
  karakalpakstan: "Qoraqalpog'iston respublikasi",
  kashkadarya: 'Qashqadaryo viloyati',
  user_feedback: 'Bizning saytimizdan foydalanish qanchalik oson?',
  user_feedback_btn: 'Fikr-mulohaza qoldirish',
  user_feedback_message: 'Fikr bildirganingiz uchun rahmat!',
  metro_search: 'Tjm nomi, metro',
  'О жилом комплексе': 'TJM haqida',
  qiymati: 'Qiymati',
  foydalanish_shartlari: 'Foydalanish shartlari',
  valyuta: 'Valyuta',
  til: 'Til',
  dollar: 'Dollar',
  'Все ЖК от застройщика': 'Quruvchidan TJM lar',
  dlTitle: 'Uysot – xonadon topish oson!',
  dlDescr:
    'Uysot mobil ilovasi yordamida xonadon qidirish jarayoni endi yanada qulay va tezkor bo‘ldi. Sizga mos variantlarni toppish uchun vaqt va kuch sarflashga hojat yo‘q – barcha imkoniyatlar bitta ilovada jamlangan!',
  reyting: 'reyting',
  'Смотреть все': "Hammasini ko'rish",
  xonalar_soni: 'Xonalar soni',
  Saralash: 'Saralash',
  filter_text: 'TJM , metro , quruvchi bilan izlash',
  Uysot_ilovasi: 'Uysot ilovasi',
  Xaritada: 'Xaritada',
  ta: 'ta',
  'Остались вопросы? Оставьте свои контакты': 'Savollaringiz bormi? Kontaktlaringizni qoldiring',
  'Ваше имя': 'Ismingiz',
  Отправить: 'Yuborish',
  'Отправляя заявку, вы даёте своё согласие на обработку персональных данных':
    "Arizani yuborish orqali siz shaxsiy ma'lumotlaringizni qayta ishlashga rozilik bildirasiz",
  'Строительные ЖК на карте': 'Quruvchi TJMlari xaritada',
  'м²': 'm²',
  bosh_tolov: "Bosh to'lov",
  narxi: 'Narxi',
  narxi_m2: 'Narxi(m²)',
  dan: 'dan',
  gacha: 'gacha',
  'Последнее обновление': "So'ngi yangilanish",
  'часов назад': 'часов назад',
  'от застройщика': 'quruvchidan',
  apartment_empty_title: 'So‘rovingiz bo‘yicha takliflar topilmadi',
  apartment_empty_descr: "Ehtimol, siz qidiruv shartlarini juda qat'iy belgilagansiz, so‘rovni o‘zgartirib ko‘ring.",
  apartment_empty_btn: 'Sozlamalarni tiklash',
  chegirma: 'Chegirma',
  Позвонить: "Qo'ng'iroq qilish",
  'Ваша заявка принята.': 'Sizning arizangiz qabul qilindi.',
  'Ваш запрос успешно получен. Наши менеджеры свяжутся с вами в ближайшее время.':
    "Sizning so'rovingiz muvaffaqiyatli qabul qilindi. Bizning menejerlarimiz tez orada siz bilan bog'lanishadi.",
  Taklif: 'Taklif',
  oylik_tolov: "Oylik to'lov",
  kredit: 'Kredit',
  foizi: 'Foizi',
  kochmas_mulk_narxi: "Ko'chmas mulk narxi",
  boshlangich_tolov: "Dastlabki to'lov",
  'Срок кредита (лет)': 'Kredit muddati (yil)',
  ariza_berish: 'Ariza berish',
  'Остались вопросы?': 'Savollaringiz bormi ?',
  'Пн-Сб. С 8:00 до 18:00': 'Du-Shan. 9:00 dan 18:00 gacha',
  Office_location: 'Toshkent, Yunusobod tumani, Yunusobod mavzesi, 12-kvartal, 20A.',
  Konsultatsiya: 'Konsultatsiya',
  кв: 'chorak',
  г: 'yil',
  'Бренд застройщика': 'Quruvchi brendi',
  Sana: 'Sana',
  Foiz: 'Foiz summa',
  'Asosiy qarz': 'Asosiy qarz',
  Qoldiq: 'Qoldiq',
  'Tolov jadvali': "To'lov jadvali",
  oylik_tolov1: "Oylik to'lov",
  'Umumiy summa': 'Umumiy summa',
  Недвижимость: "Ko'chmas mulk",
  'Цена недвижимости': "Ko'chmas mulk narxi",
  'Первоначалный взнос': "Dastlabki to'lov",
  Ставка: 'Taklif',
  'Платёж в месяц': "Oylik to'lov",
  'Сумма кредита': 'Kredit miqdori',
  'Ariza qoldirish': 'Ariza qoldirish',
  yil: 'yil',
  Программа: 'Dastur',
  Банк: 'Bank',
  Сумма: "Mablag'",
  mashhur_maqolalar: 'Eng mashhur maqolalar',
  Неизвестный: "Noma'lum",
  Объявления: "E'lon",
  'Популярные предложения': 'Mashhur takliflar',
  'Новое в Uysot': "Yangi qo'shilgan binolar",
  Ипотека: 'Ipoteka',
  muddatli_tolov: "Bo'lib to'lash",
  muddatli_tolov_kalkulyatori: "Muddatli to'lov kalkulyatori",
  kredit_kalkulyatori: 'Kredit kalkulyatori',
  about_builder: 'Quruvchi haqida',
  Yangiliklar: 'Yangiliklar',
  'Tayyor binolar': 'Tayyor binolar',
  Сдан: 'Topshirilgan',
  Blok: 'Blok',
  Bloklardagi_kvartiralar: 'Blokni ko‘rish',
  Visua_korinish: "Visual ko'rinish",
  "referral_info": "Referal haqida malumot",
  "referral_condition": "Shart quyidagicha, referal link orqali mobil ilovadan ro‘yxatdan o‘tgan har bir do‘stingiz uchun +1 UCoindan beriladi. Hoziroq do'stlaringizga ulashing va Ucoinlarni jamgarishni boshlang.",
  "each_friend": "Har bir do‘st",
  "your_referral_link": "Sizning referal havolangiz",
  "your_friends": "Sizning do‘stlaringiz",
  "copy_link": "Havoladan nusxa oling",
  "friend_registers": "Do’stingiz ro’yhatdan o’tadi",
  "get_ucoins": "Ucoinlarga ega bo'ling",
  "Изменить номер":"Raqamni o'zgartirish",
  'Повторная отправка будет доступна':"Qayta yuborish",
  "Отправить код ещё раз":"Kodni qayta yuborish",
  "Секунд":"soniyadan so'ng mavjud bo'ladi",
  "Referal":"Referal",
  "Sizda hozir qo’shilgan do’stlar mavjud emas":"Sizda hozir qo’shilgan do’stlar mavjud emas",
  tolov_kalkulyatori: "To'lov kalkulyatori",
  ipoteka_apartments: "Ipotekali kvartiralar",
  ipoteka_complexes: "Ipotekali yangi uylar",
  ipoteka_article: "Ipoteka haqidagi maqolalalar",
  "Офис продаж":"Sotuv ofisi",
  Bloklar: "Bloklar",
  "комнатная квартира": "xonali xonadon",
  tamirli: "Ta'mirli",
  tamirsiz: "Ta'mirsiz",
  maydoni: "Maydoni",
  Xona: "Xona",
  hudud_tanlash: "Hudud tanlash",
  Block:"Blok"
}
const ru = {
  Продолжить: 'Продолжить',
  'Arizangizni yuboring': 'Отправьте заявку',
  Подтвердить: 'Подтвердить',
  'Введите код из СМС': 'Введите код из СМС',
  'Каталог ЖК': 'Новостройки',
  'Карта новостроек': 'Карта',
  Квартиры: 'Квартиры',
  Застройщик: 'Застройщик',
  Застройщики: 'Застройщики',
  Журнал: 'Журнал',
  Изменить: 'Изменить',
  Меню: 'Меню',
  'Войти или зарегистрироваться': 'Войти или зарегистрироваться',
  Выйти: 'Выйти',
  Избранное: 'Избранное',
  'Ваш город': 'Ваш город',
  'Войти или создать личный кабинет': 'Войти или создать личный кабинет',
  'Номер телефона': 'Номер телефона',
  'Загрузите сейчас! Не пропустите акции и скидки': 'Скачать сейчас! Не пропустите скидки',
  'Наведите камеру на QR-код, чтобы скачать': 'Наведите камеру на QR-код, чтобы скачать',
  'Для застройщиков': 'Для застройщиков',
  'Колл-центр': 'Колл-центр',
  'Другие ЖК от застройщика': 'Другие ЖК от застройщика',
  Tugash_muddati: 'Срок сдачи',
  Ipoteka: 'Рассрочка',
  Uy_sinfi: 'Класс жилья',
  Xonadonlar: 'Квартиры',
  Infrastruktura: 'Инфраструктура',
  Genplan: 'Генплан',
  Haqida: 'Информация о ЖК',
  Ulashish: 'Поделиться',
  Saqlanganlar: 'В избранное',
  ЖК: 'ЖК',
  Ipoteka_text: 'не предостовлено',
  Quruvchi: 'Строитель',
  'Узнать цену': 'Узнать цену',
  'Nusxa olish': 'Копировать',
  'Nusxa olindi': 'Скопировано',
  title: 'Новостройки в Ташкенте - Купите квартиру в новостройке',
  description: 'Купите квартиру в новых жилых комплексах Ташкента. Лучшие цены и предложения на сайте Uysot.uz.',
  Договорная: 'Договорная',
  'Показать телефон': 'Показать телефон',
  'Перезвоните мне': 'Перезвоните мне',
  'Ushbu sahifa topilmadi!': 'Эта страница не найдена!',
  'malumot topilmadi': 'Информация не найдена',
  'Sahifa mavjud emas': 'Извините, страница, которую вы ищете, не существует или была перемещена.',
  'Bosh sahifaga qaytish': 'Вернуться на главную',
  'Yuklab olish': 'Скачать',
  Saqlash: 'Сохранить',
  Til: 'Язык',
  Valyuta: 'Валюта',
  expo_title: 'ПОЗДРАВЛЯЕМ! ВЫ СТАЛИ УЧАСТНИКОМ UYSOT EXPO!',
  expo_ticket: 'Скачивайте билет и участвуйте в выставке «UYSOT EXPO», которая пройдет 2-3 марта.',
  expo_body: 'У ВАС ЕСТЬ ШАНС ВЫИГРАТЬ ЭТИ ПРИЗЫ',
  Dubayga_chipta: 'Билет в Дубай',
  'Umra ziyoratiga yo’llanma': 'Билет на паломничество Умра',
  '50 dan ortiq boshqa sovg’alar': 'Более 50 других подарков',
  expo_footer: 'Правила: Для участия в этой выигрышной игре необходимо посетить UYSOT EXPO 2-3 марта.',
  dynamic_empty_title: 'Актуальной информации нет!',
  dynamic_empty_descr: 'К сожалению, актуальной информации о ЖК, который вы ищете, нет. Скоро формируемся.',
  Tez_tez_beriladigan_savollar: 'Часто задаваемые вопросы',
  Eng_kop_beriladigan_savollar: 'Наиболее часто задаваемые вопросы',
  TOP: 'ТОП',
  Toshkentdagi_yangi_uylar: 'Новостройки города Ташкента',
  Toshkentdagi_yangi_kvartiralar: 'Квартиры города Ташкента',
  Butun_Ozbekiston_boylab_yangi_uylar: 'Новостройки по всему Узбекистану',
  Butun_Ozbekiston_boylab_kvartiralar: 'Квартиры по всему Узбекистану',
  takliflar: 'Квартиры в новостройках (ЖК) Узбекистане',
  Batafsil: 'Подробнее',
  Hammasi: 'Все',
  Qurilish_jarayoni: 'Процесс строительства',
  notif_title: 'Подпишитесь на ограниченные предложения',
  notif_text: 'Будьте первым, кто совершает покупки, используя эксклюзивные скидки и акции только для участников.',
  notif_button: 'Подпишитесь',
  ishonchli: 'Надежный',
  tasdiqlangan: 'Подтвержденный',
  seo_text: `Устали от аренды? Настало время воплотить мечту о собственном жилье с нашей платформой! На <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> вы найдете самые доступные, качественные и надежные новостройки по всему Узбекистану. В нашем каталоге представлены современные квартиры и дома на любой вкус — от квартир безремонта до полностью отремонтированных жилых помещений. <br /> <br /> Мы предлагаем самые актуальные предложения с возможностью беспроцентной рассрочки, кредита и ипотеки, чтобы покупка жилья стала реальностью для каждого. Благодаря техническим характеристикам и планировкам вы сможете легко выбрать дом своей мечты. Наши данные регулярно обновляются, поэтому вы всегда будете в курсе последних цен и предложений. <br /> <br /> <a style="color: #1570EF" href="https://uysot.uz" target="_blank" rel="noopener noreferrer">Uysot.uz</a> — ваш надежный партнер на пути к приобретению собственного жилья. Забудьте об аренде, выберите новый дом по доступной цене уже сегодня!`,
  seo_title: 'Новостройки в Узбекистане',
  document: 'документ',
  documents: 'Документы',
  tolov_summasi: 'Сумма платежа',
  Расположение: 'Расположение',
  Фильтр: 'Фильтр',
  tolov_turi: 'Тип платежа',
  'Журнал UYSOT': 'Журнал UYSOT',
  'Head title': 'Блог о недвижимости в Ташкенте: новости, советы, аналитика',
  elon_joylash: 'Дорогой застройщик!',
  murojaat_qilish: 'Связаться с нами',
  TJM_joylashtiring_xaridorlarni_jalb_qiling: 'Нужны качественные лиды?',
  builder_quiz_title: 'Продавайте новостройки быстрее – размещайте объявления на нашей платформе!',
  builder_quiz_text:
    'Размещайте свои объекты на нашей платформе и привлекайте больше покупателей. Помощь и большая аудитория ждут на каждом этапе!',
  keyingisi: 'Следующий',
  ortga: 'Назад',
  savol: 'вопрос',
  oxirgi_savol: 'Последний вопрос',
  xonadoni_qayerdan_qidiramiz: 'Где вы ищете квартиру?',
  ariza_yuborish: 'Отправить заявку',
  ming: 'тыс',
  mln: 'млн',
  mlrd: 'млрд',
  trln: 'трлн',
  FISH: 'Ф.И.О',
  tanlash_qiyinmi: 'Трудно выбрать?',
  mos_uy: 'поможет Вам найти подходящую квартиру.',
  xonalik: 'комнатная',
  siz_nechi_xonalik_kvartirani_qidiryapsiz: 'Сколько комнатную квартиру вы ищете?',
  multi_select_rooms: 'Вы можете выбрать несколько вариантов',
  uy_joy_xarid_qilish_usuli: 'Способы покупки жилья',
  narx_oraligi: 'В каком ценовом диапазоне вы хотите?',
  ism_telefon_raqam: 'Запишите свое имя и номер телефона!',
  ism_telefon_raqam_text: 'Это необходимо для того, чтобы мы могли с вами связаться.',
  muvaffaqqiyatli: 'Успешный',
  siz_bilan_boglanamiz: 'Готово! Ваше заявление отправлено, скоро с вами свяжемся.',
  uysotdan_taklif: 'Проведите свое свободное время с пользой – есть советы от Uysot!',
  oylik_tolov_oraligi: 'В каком диапазоне цен вы хотите, чтобы ежемесячный платеж был?',
  boshlangich_tolov_oraligi: 'В каком ценовом диапазоне вы бы хотели получить первоначальный взнос?',
  quiz_card_title: 'Трудно искать, находить и выбирать квартиру?',
  quiz_card_text:
    'Опишите дом своей мечты команде Uysot. Мы найдем для вас подходящее жилье из более чем 20 000 квартир.',
  kvartiri: 'квартиры',
  novostroyki: 'новостройки',
  'Обратная связь': 'Обратная связь',
  Имя: 'Имя',
  Рынок_новых_домов: 'Рынок новых домов',
  Скачать: 'Скачать',
  'Ипотечный калькулятор': 'Ипотечный калькулятор',
  'Похожие новостройки': 'Похожие новостройки',
  'Похожие квартиры': 'Похожие квартиры',
  'Похожие статьи': 'Похожие статьи',
  Cтатьи: 'Cтатьи',
  see_more: 'Посмотреть больше',
  tozalash: 'Очистить',
  mintaqa: 'Выберите регион',
  xonadon: 'Квартира',
  maydon: 'Площадь',
  qavat: 'Этаж',
  holati: 'Статус',
  TJM: 'Жилой комплекс',
  muddati: 'Срок',
  'Chegirma va bonuslar': 'Скидки и бонусы',
  tolov: 'Оплата',
  turi: 'Тип',
  'Boshlangich tolov': 'Первоначальный платеж',
  'Перв. взнос': 'Перв. взнос',
  'Oylik tolov': 'Ежем. платеж',
  'Tolov muddati': 'Период оплаты',
  Qidirish: 'Поиск',
  Найти: 'Найти',
  'Natijani koratish': 'Показать результат',
  studiya: 'Студия',
  xona: 'комната',
  Sinfi: 'Класс',
  'Foydalanish shartlari': 'Условия использования',
  Yashirish: 'Скрывать',
  qurulish_jarayoni: 'Строительный процесс',
  qurulish_hujjatlari: 'Строительные документы',
  genplan: "Визуальный вид",
  badge_text: 'Подтверждено со стороны Uysot',
  'Ijtimoiy tarmoqlar': 'Социальные сети',
  'Profilingizga kiring': 'Войдите в свой профиль',
  Kirish: 'Войти или Зарегистрироваться',
  Chiqish: 'Выход',
  'Mintaqa tanlang': 'Выберите регион',
  ozbek_somi: 'Узбекский сум',
  aqsh_dollari: 'Доллар США',
  login: 'Войти',
  'Смотреть фото': 'Смотреть фото',
  korinish_turi: 'Тип просмотра',
  Xarita: 'Карта',
  royxat: 'Список',
  qaynoq_takliflar: 'Горячие предложения',
  sum: 'сум',
  oy: 'месяц',
  tjm_royxat: 'Список ЖК',
  xonadonlar_royxati: 'список квартир',
  "Ta'rifi": 'Описание',
  Joylashuv: 'Расположение',
  "Ma'lumotlar": 'Информация',
  Butun_Ozbekiston_boylab: 'По всему Узбекистану',
  Bonuslar: 'Бонусы',
  kun: 'день',
  faollashtirish: 'Активация',
  qayta_yuklash: 'Перезагрузить',
  aksiya_bonuslar: 'Акции и бонусы',
  bonus_failed_text: 'Ваш контракт неактивен. Пожалуйста, активируйте контракт и отправьте его нам.',
  bonus_view_text: 'Ваш договор рассматривается.',
  bonus_success_text: 'Ваш договор был подтверждён. В ближайшее время мы с вами свяжемся.',
  shartnomani_tasdiqlatish: 'Подтверждение договора',
  faol: 'Активный',
  Metro: 'Метро',
  'Metro yaqinidagi uylar': 'Новостройки рядом с метро',
  'Bonuslar mavjud emas!': 'Бонусов нет!',
  Показать_больше: 'Показать больше',
  Показать_меньше: 'Показать меньше',
  'Metro yaqinidagi kvartiralar': 'Дома рядом с метро',
  tashkent: 'Ташкентская область',
  'gorod-tashkent': 'город Ташкент',
  navoi: 'Навоийская область',
  samarkand: 'Самаркандская область',
  bukhara: 'Бухарская область',
  buxara: 'Бухарская область',
  andijan: 'Андижанская область',
  surxandarya: 'Сурхандарьинская область',
  djizak: 'Джизакская область',
  xorezm: 'Хорезмская область',
  fergana: 'Ферганская область',
  sirdarya: 'Сырдарьинская область',
  namangan: 'Наманганская область',
  karakalpakstan: 'Республика Каракалпакстан',
  kashkadarya: 'Кашкадарьинская область',
  user_feedback: 'Насколько легко пользоваться нашим сайтом?',
  user_feedback_btn: 'Оставить отзыв',
  user_feedback_message: 'Спасибо за ваш отзыв!',
  metro_search: 'Название ЖК, метро',
  'О жилом комплексе': 'О жилом комплексе',
  'Все ЖК от застройщика': 'Все ЖК от застройщика',
  qiymati: 'Цена',
  foydalanish_shartlari: 'Условия использования',
  valyuta: 'Валюта',
  til: 'Язык',
  dollar: 'Доллар',
  dlTitle: 'Uysot – найти дом легко!',
  dlDescr:
    'С помощью мобильного приложения Uysot процесс поиска жилья стал еще удобнее и быстрее. Вам больше не нужно тратить время и усилия на поиск подходящих вариантов – все возможности собраны в одном приложении!',
  reyting: 'рейтинг',
  'Смотреть все': 'Смотреть все',
  xonalar_soni: 'Количество комнат',
  Saralash: 'Сортировка',
  filter_text: 'Поиск с ЖК, метро, застройщик',
  Uysot_ilovasi: 'Приложение Uysot',
  Xaritada: 'На карте',
  ta: 'штук',
  'Остались вопросы? Оставьте свои контакты': 'Остались вопросы? Оставьте свои контакты',
  'Ваше имя': 'Ваше имя',
  Отправить: 'Отправить',
  'Отправляя заявку, вы даёте своё согласие на обработку персональных данных':
    'Отправляя заявку, вы даёте своё согласие на обработку персональных данных',
  'Строительные ЖК на карте': 'Строительные жилые комплексы на карте',
  'м²': 'м²',
  bosh_tolov: 'Перв. платеж',
  narxi: 'Цена',
  narxi_m2: 'Цена(м²)',
  dan: 'от',
  gacha: 'до',
  'Последнее обновление': 'Последнее обновление',
  'часов назад': 'часов назад',
  'от застройщика': 'от застройщика',
  apartment_empty_title: 'Мы не нашли предложений по вашему запросу',
  apartment_empty_descr: 'Возможно, вы задали жесткие условия поиска, попробуйте изменить запрос',
  apartment_empty_btn: 'Сбросить параметры',
  chegirma: 'Скидка',
  Позвонить: 'Позвонить',
  'Ваша заявка принята.': 'Ваша заявка принята.',
  'Ваш запрос успешно получен. Наши менеджеры свяжутся с вами в ближайшее время.':
    'Ваш запрос успешно получен. Наши менеджеры свяжутся с вами в ближайшее время.',
  Taklif: 'Ставка',
  oylik_tolov: 'Ваш ежемесячный платеж',
  kredit: 'Кредит',
  foizi: 'Процент',
  kochmas_mulk_narxi: 'Цена недвижимости',
  boshlangich_tolov: 'Первоначалный взнос',
  'Срок кредита (лет)': 'Срок кредита (лет)',
  ariza_berish: 'Подать заявку',
  'Остались вопросы?': 'Остались вопросы?',
  'Пн-Сб. С 8:00 до 18:00': 'Пн-Сб. С 9:00 до 18:00',
  Office_location: 'Ташкент, Юнусабадский район, массив Юнусабад, 12-й квартал, 20A.',
  Konsultatsiya: 'Консультация',
  кв: 'кв',
  г: 'г',
  'Бренд застройщика': 'Бренд застройщика',
  Sana: 'Дата',
  Foiz: 'Сумма процентов',
  'Asosiy qarz': 'Основной долг',
  Qoldiq: 'Остаток',
  'Tolov jadvali': 'График платежей',
  oylik_tolov1: 'Ежемесячный платеж',
  'Umumiy summa': 'Общая сумма',
  Недвижимость: 'Недвижимость',
  'Цена недвижимости': 'Цена недвижимости',
  'Первоначалный взнос': 'Первоначалный взнос',
  Ставка: 'Ставка',
  'Платёж в месяц': 'Платёж в месяц',
  'Сумма кредита': 'Сумма кредита',
  'Ariza qoldirish': 'Подать заявку',
  yil: 'год',
  Программа: 'Программа',
  Банк: 'Банк',
  Сумма: 'Сумма',
  mashhur_maqolalar: 'Самые популярные статьи',
  Неизвестный: 'Неизвестный',
  Объявления: 'Объявления',
  'Популярные предложения': 'Популярные предложения',
  'Новое в Uysot': 'Новое в Uysot',
  Ипотека: 'Ипотека',
  muddatli_tolov: 'Рассрочка',
  muddatli_tolov_kalkulyatori: 'Калькулятор рассрочки',
  kredit_kalkulyatori: 'Кредитный калькулятор',
  about_builder: 'О застройщике',
  Yangiliklar: 'Новости',
  'Tayyor binolar': 'Готовые новостройки',
  Сдан: 'Сдан',
  Blok: 'Блок',
  Bloklardagi_kvartiralar: 'Квартиры в блок',
  Visua_korinish: 'Визуальный вид',
  "referral_info": "Информация о рефералах",
  "referral_condition": "Условие следующее: за каждого друга, зарегистрировавшегося через реферальную ссылку в мобильном приложении, вы получите +1 UCoin. Делитесь ссылкой с друзьями прямо сейчас и начинайте собирать UCoin'ы.",
  "each_friend": "Каждый друг",
  "your_referral_link": "Ваша реферальная ссылка",
  "your_friends": "Ваши друзья",
  "copy_link": "Скопируйте ссылку",
  "friend_registers": "Ваш друг зарегистрируется",
  "get_ucoins": "Получайте UCoin'ы",
  "Изменить номер":"Изменить номер",
  "Повторная отправка будет доступна":"Повторная отправка будет доступна",
  "Отправить код ещё раз":"Отправить код ещё раз",
  "Секунд":"сек",
  "Referal":"Реферал",
  "Sizda hozir qo’shilgan do’stlar mavjud emas":"У вас сейчас нет добавленных друзей",
  tolov_kalkulyatori: "Калькулятор оплаты",
  ipoteka_apartments: "Ипотечные квартиры",
  ipoteka_complexes: "Ипотечные новостройки",
  ipoteka_article: "Статьи об ипотеке",
  "Офис продаж":"Офис продаж",
  Bloklar: "Блоки",
  "комнатная квартира": "комнатная квартира",
  tamirli: "С ремонтом",
  tamirsiz: "Без ремонтом",
  maydoni: "Площадь",
  Xona: "Комната",
  hudud_tanlash: "Выберите регион",
  Block:'Блок'
};

// eslint-disable-next-line @typescript-eslint/ban-types
type LangType = Record<keyof typeof uz | (string & {}), string>;


export const layoutLang: {
  [key: string]: LangType;
} = {
  uz,
  ru
};
