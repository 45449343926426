import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { localStorageKeys } from "../../utils/constants/localStorageKeys"
import { sliceNames } from "../../utils/constants/sliceNames"
import { isBrowser } from "../../utils/helpers/isBrowser"
import { NewApartmentCardModel } from "../../utils/models/apartmentModel"
import { NewResidentalComplexModel } from "../../utils/models/residentialComplexModel"
import queryString from "query-string"
import { AllRegionsModel } from "pages/main-layout/components/region-city/utils/models/AllRegionsModel"

const pased: any = isBrowser() && queryString.parse(location.search);

type FilterType = {
  city_id: number | null;
  district_id: number | null;
  min_floor: number | null;
  max_floor: number | null;
  rooms_count: string[];
  repaired: boolean | null;
  discount: boolean | null;
  min_total_price: number | null;
  max_total_price: number | null;
  min_price: number | null;
  max_price: number | null;
  min_prepayment: number | null;
  max_prepayment: number | null;
  min_monthly_price: number | null;
  max_monthly_price: number | null;
  min_area: number | null;
  max_area: number | null;
  delays: string[];
  class_id: string | null;
  deadline: string | null;
  deadline_years: string[];
  complex_name: string | null;
  payment_method_ids: string[];
  class_ids: string[];
  floor: number | null;
};

export type PricesType =
  | 'min_area'
  | 'max_area'
  | 'min_total_price'
  | 'max_total_price'
  | 'max_floor'
  | 'min_floor'
  | 'min_prepayment'
  | 'max_prepayment'
  | 'max_monthly_price'
  | 'min_monthly_price'
  | 'min_price'
  | 'max_price';

type initialState = {
  complex: NewResidentalComplexModel[];
  apartment: NewApartmentCardModel[];
  expoModal: boolean;
  filter: FilterType;
  windowPosition: {
    activeKey?: string | undefined;
    positionY?: number;
  };
  expoImg: string;
  mapDrawer: { open: boolean; id: number | null };
  filterType: 'apartment' | 'complex';
  region: string | null;
  location: {
    openRegionSelect: boolean;
    searchValue: string;
    regionValue: AllRegionsModel | undefined;
    mainRegionValue: AllRegionsModel | undefined;
  };

  hotOffersRegionValue: AllRegionsModel | undefined;
  locationType: 'location' | 'hot_location' | 'metro' | 'mobile_filter' | 'mobile_location' | undefined;
  metroName:
    | {
        uz: string;
        ru: string;
      }
    | undefined;
  coinVisible: boolean;
  mapOptions: number[];
  filterVisible: boolean;
  mortgageCalculator: {
    price: number
    initialFee: number
    term: number
    percent: number
    monthlyPrice: number
    activeSegment: number
  }
  complex_apartments_filter: boolean
  block_id: number | null
  activeFilter: number | undefined
  openApartmentFilter: {
    type: "block" | "price",
    visible: boolean
  }
  complexOpen: boolean
  openApartments: boolean
  openDesktopModal: boolean
}

const initialState: initialState = {
  complex: isBrowser() ? JSON.parse(localStorage.getItem(localStorageKeys.COMPLEX) || '[]') : [],
  apartment: isBrowser() ? JSON.parse(localStorage.getItem(localStorageKeys.APARTMENT) || '[]') : [],
  expoModal: true,
  filter: {
    class_id: pased?.class_id ? pased?.class_id : null,
    deadline: pased?.deadline ? pased?.deadline : null,
    delays: pased?.delays ? pased?.delays : [],
    district_id: pased?.district_id ? Number(pased?.district_id) : null,
    min_area: pased?.min_area ? Number(pased?.min_area) : null,
    max_area: pased?.max_area ? Number(pased?.max_area) : null,
    min_floor: pased?.min_floor ? Number(pased?.min_floor) : null,
    max_floor: pased?.max_floor ? Number(pased?.max_floor) : null,
    min_total_price: pased?.min_total_price ? Number(pased?.min_total_price) : null,
    max_total_price: pased?.max_total_price ? Number(pased?.max_total_price) : null,
    max_prepayment: pased?.max_prepayment ? Number(pased?.max_prepayment) : null,
    min_prepayment: pased?.min_prepayment ? Number(pased?.min_prepayment) : null,
    max_price: pased?.max_price ? Number(pased?.max_price) : null,
    min_price: pased?.min_price ? Number(pased?.min_price) : null,
    city_id: pased?.city_id ? Number(pased?.city_id) : null,
    repaired: pased?.repaired ? pased?.repaired : null,
    discount: pased?.discount ? pased?.discount : null,
    rooms_count: pased?.rooms_count ? Array.isArray(pased?.rooms_count) ? pased?.rooms_count : [pased?.rooms_count] : [],
    complex_name: pased?.complex_name ? pased?.complex_name : null,
    payment_method_ids: pased?.payment_method_ids ? pased?.payment_method_ids : [],
    class_ids: pased?.class_ids ? pased?.class_ids : [],
    deadline_years: pased?.deadline_years ? pased?.deadline_years : [],
    max_monthly_price: pased?.max_monthly_price ? Number(pased?.max_monthly_price) : null,
    min_monthly_price: pased?.min_monthly_price ? Number(pased?.min_monthly_price) : null,
    floor: null
  },
  hotOffersRegionValue: undefined,
  windowPosition: {
    activeKey: undefined,
    positionY: 0
  },
  expoImg: '',
  mapDrawer: { id: null, open: false },
  filterType: 'complex',
  region: null,
  location: {
    openRegionSelect: false,
    regionValue: { city: null, district: null },
    mainRegionValue: { city: null, district: null },
    searchValue: ''
  },

  locationType: undefined,
  metroName: undefined,
  coinVisible: false,
  mapOptions: [40.008544, 65.36646, 7],
  filterVisible: true,
  mortgageCalculator: {
    price: 600000000,
    initialFee: 100000000,
    term: 20,
    percent: 17.5,
    monthlyPrice: 6000000,
    activeSegment: 1
  },
  complex_apartments_filter: false,
  block_id: null,
  activeFilter: undefined,
  openApartmentFilter: {
    type: "price",
    visible: false
  },
  complexOpen: false,
  openApartments: false,
  openDesktopModal: false
}

const favouritesReducer = createSlice({
  name: sliceNames.FAVOURITES,
  initialState,
  reducers: {
    appendApartment: (state, action: PayloadAction<NewApartmentCardModel>) => {
      const newData = [...state.apartment, action.payload];

      state.apartment = newData;
      localStorage.setItem(localStorageKeys.APARTMENT, JSON.stringify(newData));
    },
    removeApartment: (state, action: PayloadAction<number>) => {
      const newData = state.apartment.filter(item => item.id !== action.payload);

      state.apartment = newData;
      localStorage.setItem(localStorageKeys.APARTMENT, JSON.stringify(newData));
    },
    appendComplex: (state, action: PayloadAction<NewResidentalComplexModel>) => {
      const newData = [...state.complex, action.payload];

      state.complex = newData;
      localStorage.setItem(localStorageKeys.COMPLEX, JSON.stringify(newData));
    },
    removeComplex: (state, action: PayloadAction<number>) => {
      const newData = state.complex.filter(item => item.id !== action.payload);

      state.complex = newData;
      localStorage.setItem(localStorageKeys.COMPLEX, JSON.stringify(newData));
    },
    setComplexes: (state, action: PayloadAction<NewResidentalComplexModel[]>) => {
      const newData = action.payload;

      state.complex = newData;
      localStorage.setItem(localStorageKeys.COMPLEX, JSON.stringify(newData));
    },
    clearFavourites: state => {
      state.apartment = [];
      state.complex = [];
      localStorage.removeItem(localStorageKeys.APARTMENT);
      localStorage.removeItem(localStorageKeys.COMPLEX);
    },
    closeExpoModal: (state, action: PayloadAction<boolean>) => {
      state.expoModal = action.payload;
    },
    setStudio: (state, action: PayloadAction<string[]>) => {
      state.filter.rooms_count = action.payload;
    },
    setMinTotalPrice: (state, action: PayloadAction<number>) => {
      state.filter.min_total_price = action.payload;
    },
    setMaxTotalPrice: (state, action: PayloadAction<number>) => {
      state.filter.max_total_price = action.payload;
    },
    setMinAreaPrice: (state, action: PayloadAction<number>) => {
      state.filter.min_price = action.payload;
    },
    setMaxAreaPrice: (state, action: PayloadAction<number>) => {
      state.filter.max_price = action.payload;
    },
    setMinPrePayment: (state, action: PayloadAction<number>) => {
      state.filter.min_prepayment = action.payload;
    },
    setMaxPrePayment: (state, action: PayloadAction<number>) => {
      state.filter.max_prepayment = action.payload;
    },
    setMinMonthlyPayment: (state, action: PayloadAction<number>) => {
      state.filter.min_monthly_price = action.payload;
    },
    setMaxMonthlyPayment: (state, action: PayloadAction<number>) => {
      state.filter.max_monthly_price = action.payload;
    },
    setChangeComplexName: (state, action: PayloadAction<string | null>) => {
      state.filter.complex_name = action.payload;
    },
    clearFilters: state => {
      state.filter.class_id = null;
      state.filter.complex_name = null;
      state.filter.deadline = null;
      state.filter.delays = [];
      state.filter.class_ids = [];
      state.filter.deadline_years = [];
      state.filter.district_id = null;
      state.filter.max_area = null;
      state.filter.max_floor = null;
      state.filter.max_total_price = null;
      state.filter.min_area = null;
      state.filter.min_floor = null;
      state.filter.min_total_price = null;
      state.filter.max_prepayment = null;
      state.filter.min_prepayment = null;
      state.filter.max_price = null;
      state.filter.min_price = null;
      state.filter.city_id = null;
      state.filter.repaired = null;
      state.filter.discount = null;
      state.filter.rooms_count = [];
      state.filter.payment_method_ids = [];
      state.filter.min_monthly_price = null;
      state.filter.max_monthly_price = null;
      state.filter.floor = null;
    },
    setWindowPosition: (
      state,
      action: PayloadAction<{
        activeKey?: string | undefined;
        positionY: number;
      }>
    ) => {
      state.windowPosition.activeKey = action.payload.activeKey;
      state.windowPosition.positionY = action.payload.positionY;
    },
    setChangeValue: (
      state,
      action: PayloadAction<{
        type: PricesType;
        value: number | null;
      }>
    ) => {
      const { type, value } = action.payload;
      state.filter[type] = value;
    },
    setChangeFiltersValue: (
      state,
      action: PayloadAction<{
        type: 'delays' | 'deadline_years' | 'rooms_count' | 'payment_method_ids' | 'class_ids';
        value: string;
      }>
    ) => {
      const { type, value } = action.payload;
      if (state.filter[type]?.includes(value)) {
        const newValue = state.filter[type]?.filter(item => item !== value);
        state.filter[type] = newValue;
      } else {
        state.filter[type] = [state.filter[type], value]?.flat(Infinity) as string[];
      }
    },
    setRepairedAndDiscount: (
      state,
      action: PayloadAction<{
        type: 'discount' | 'repaired';
        value: boolean | null;
      }>
    ) => {
      const { type, value } = action.payload;

      if (state.filter[type] === value) {
        state.filter[type] = null;
      } else {
        state.filter[type] = value;
      }
    },
    setDiscount: (state, action: PayloadAction<boolean | null>) => {
      state.filter.discount = action.payload;
    },
    setDistrictId: (state, action: PayloadAction<number | null>) => {
      state.filter.district_id = action.payload;
    },
    setRegionId: (state, action: PayloadAction<number | null>) => {
      state.filter.city_id = action.payload;
    },
    clearByKey: (state, action: PayloadAction<{ key: 'rooms_count' | 'deadline_years' | 'payment_method_ids' | 'delays' | 'class_ids' }>) => {
      const { key } = action.payload;
      state.filter[key] = [];
    },
    setOpenMapDrawer: (state, action: PayloadAction<{ id: number | null; open: boolean }>) => {
      state.mapDrawer.id = action.payload.id;
      state.mapDrawer.open = action.payload.open;
    },
    setChangeFilterType: (state, action: PayloadAction<'complex' | 'apartment'>) => {
      state.filterType = action.payload;
    },
    setRegion: (state, action: PayloadAction<string | null>) => {
      state.region = action.payload;
    },
    setOpenRegionSelect: (
      state,
      action: PayloadAction<{
        type: 'location' | 'hot_location' | 'metro' | 'mobile_filter' | 'mobile_location' | undefined;
        value: boolean;
      }>
    ) => {
      state.locationType = action.payload.type;
      state.location.openRegionSelect = action.payload.value;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.location.searchValue = action.payload;
    },
    setCurrentRegion: (
      state,
      action: PayloadAction<{
        type: 'location' | 'hot_location' | 'metro' | 'mobile_filter' | "main" | 'mobile_location' | undefined;
        value: AllRegionsModel | undefined;
      }>
    ) => {
      if (action.payload.type === 'location') {
        state.location.regionValue = action.payload.value;
      } else if (action.payload.type === "main") {
        state.location.mainRegionValue = action.payload.value
      } else {
        state.hotOffersRegionValue = action.payload.value;
      }
    },

    setFilterVisible: (state, action: PayloadAction<boolean>) => {
      state.filterVisible = action.payload;
    },
    setComplexApartmentsFilter: (state, action: PayloadAction<boolean>) => {
      state.complex_apartments_filter = action.payload;
    },

    setMapOptions: (state, action: PayloadAction<number[]>) => {
      state.mapOptions = action.payload;
    },
    setCoinVisible: (state, action: PayloadAction<boolean>) => {
      state.coinVisible = action.payload;
    },
    setChangeMortgageItems: (state, action: PayloadAction<{ type: keyof typeof state.mortgageCalculator, value: number }>) => {
      state.mortgageCalculator[action.payload.type as keyof typeof state.mortgageCalculator] = action.payload.value
    },
    setActiveSegment: (state, action: PayloadAction<number>) => {
      state.mortgageCalculator.activeSegment = action.payload
    },
    setBlockIds: (state, action: PayloadAction<number | null>) => {
      state.block_id = action.payload
    },
    setFloor: (state, action: PayloadAction<number | null>) => {
      state.filter.floor = action.payload
    },
    setActiveFilter: (state, action: PayloadAction<number | undefined>) => {
      state.activeFilter = action.payload
    },
    setApartmentFilter: (state, action: PayloadAction<{type: "price" | "block", visible: boolean}>) => {
      state.openApartmentFilter.type = action.payload.type
      state.openApartmentFilter.visible = action.payload.visible
    },
    setComplexOpen: (state, action: PayloadAction<boolean>) => {
      state.complexOpen = action.payload
    },
    setOpenApartments: (state, action: PayloadAction<boolean>) => {
      state.openApartments = action.payload
    },
    setClearFilterPrices: state => {
      state.filter.min_price = null
      state.filter.max_price = null
      state.filter.min_total_price = null
      state.filter.max_total_price = null
      state.filter.min_monthly_price = null
      state.filter.max_monthly_price = null
      state.filter.min_prepayment = null
      state.filter.max_prepayment = null
    },
    setOpenDesktopModal: (state, action: PayloadAction<boolean>) => {
      state.openDesktopModal = action.payload
    }
  }
});

export default favouritesReducer.reducer;
export const favouritesReducerActions = favouritesReducer.actions;
