globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"9189be0166ceb7fe95f52c61a3ba4272c0d93c41"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { isBrowser } from 'utils/helpers/isBrowser';

Sentry.init({
  dsn: 'https://756d55fd390223ee05461efd9453bb09@o4508640350306304.ingest.de.sentry.io/4508687231287376',

  // Add optional integrations for additional features
  integrations: [Sentry.replayIntegration()],

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 0.5,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend: event => {
    if (isBrowser()) {
      if (window.location.hostname === 'localhost' || window.location.hostname === 'stage.marketplace.aws.softex.uz') {
        return null;
      }
    }

    return event;
  }
});
