import { useRouter } from 'next/router';
import Header from 'pages/main-layout/components/header/Header';
import React, { useEffect
//  useState 
} from 'react';
import styles from './layoutHeader.module.scss';
// import { isBrowser } from 'utils/helpers/isBrowser'
import FilterDesktop from 'common/filter-desktop/FilterDesktop';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
const LayoutHeader = () => {
  // const [position, setPosition] = useState(isBrowser() ? window.scrollY : 0)
  const {
    pathname,
    query
  } = useRouter();
  const dispatch = useAppDispatch();
  const filterVisible = useAppSelector(state => state.favouritesReducer.filterVisible);
  const {
    setFilterVisible
  } = favouritesReducerActions;
  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      dispatch(setFilterVisible(lastScrollY > currentScrollY));
      lastScrollY = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [dispatch, setFilterVisible]);
  const isPad = pathname === "/[location]/kvartiri" || pathname === "/[location]/novostroyki";
  const isChild = query?.id || query?.apartmentId || query?.builderId;
  const headerHeight = pathname !== '/dl' && pathname !== '/[location]/kvartiri/[apartmentId]' && pathname !== '/[location]/novostroyki/[id]' && pathname !== '/zastroyshiki/[builderId]' && pathname !== '/jurnal/[id]' ? '70px' : '48px';
  const isDetailedArticleInWebView = pathname.includes('/jurnal') && query?.id;
  return <>
      <div className={`${isPad ? "pb-24" : isChild ? 'md:pb-[70px] pb-12' : "md:pb-[70px] pb-[94px]"} removed_item`} />
      <div className='w-full bg-white'>
        <header className={`${styles.header} ${filterVisible ? styles.visible : styles.hidden} h-[${headerHeight}] ${isDetailedArticleInWebView ? 'removed_item' : ''}`}>
          <Header data-sentry-element="Header" data-sentry-source-file="LayoutHeader.tsx" />
        </header>
      </div>
      <div className={`desctop_only sticky ${filterVisible ? "top-[70px]" : "top-0"} z-[1000]`}>
        {(pathname === "/[location]/novostroyki" || pathname === "/[location]/kvartiri" || pathname === "/karta") && <FilterDesktop />}
      </div>
    </>;
};
export default LayoutHeader;