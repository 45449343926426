import dayjs from "dayjs";
import React from "react";
import InstagramIcon from "../../../../assets/icons/InstagramIcon";
import TelegramIcon from "../../../../assets/icons/TelegramIcon";
import style from "./Footer.module.scss";
import { layoutLang } from "../../../../utils/language/layout";
import { useRouter } from "next/router";
import CustomLink from "common/custom-link";
import YoutubeIcon from "assets/icons/youtube-icon";
import FacebookIcon from "assets/icons/facebook-icon";
import GmailIcon from "assets/icons/gmail-icon";
import FooterPhoneIcon from "assets/icons/footer-phone-icon";
import { RU } from "utils/constants/locales";
const year = dayjs().year();
function Footer() {
  const {
    locale,
    query,
    pathname
  } = useRouter();
  return <footer className={style.footer} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="wrapper">
        <div className={style.footer_top}>
          <div className={style.footer_top_links}>
            <CustomLink href={"https://business.uysot.uz/?utm_source=uysot_market&utm_medium=site&utm_campaign=promo"} target="_blank" data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                {layoutLang[String(locale)]["Для застройщиков"]}
              </span>
            </CustomLink>
            <CustomLink href={"/faq"} data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>{layoutLang[String(locale)].Tez_tez_beriladigan_savollar}</span>
            </CustomLink>
            <CustomLink href={"https://docs.google.com/document/d/152DlTJXqCBVfZQFmaKGWBden5-hzEXugRbwWSP92VaA"} target="_blank" data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                {layoutLang[String(locale)]["Foydalanish shartlari"]}
              </span>
            </CustomLink>
            <CustomLink href={"/dl"} data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                {layoutLang[String(locale)]["Uysot_ilovasi"]}
              </span>
            </CustomLink>
          </div>
          <div className={style.footer_top_messenger}>
            <CustomLink href={"https://www.facebook.com/uysot.uz"} target="_blank" aria-label="youtube url" data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                <FacebookIcon data-sentry-element="FacebookIcon" data-sentry-source-file="Footer.tsx" />
              </span>
            </CustomLink>
            <CustomLink href={"https://www.youtube.com/@uysot_uz"} target="_blank" aria-label="youtube url" data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                <YoutubeIcon data-sentry-element="YoutubeIcon" data-sentry-source-file="Footer.tsx" />
              </span>
            </CustomLink>
            <CustomLink href={"https://t.me/uysot_uz"} target="_blank" aria-label="telegram url" data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                <TelegramIcon data-sentry-element="TelegramIcon" data-sentry-source-file="Footer.tsx" />
              </span>
            </CustomLink>
            <CustomLink href={"https://www.instagram.com/uysot_uz/"} target="_blank" aria-label="instagram url" data-sentry-element="CustomLink" data-sentry-source-file="Footer.tsx">
              <span>
                <InstagramIcon data-sentry-element="InstagramIcon" data-sentry-source-file="Footer.tsx" />
              </span>
            </CustomLink>
          </div>
        </div>
        <div className={`${style.footer_bottom} ${query?.id && pathname?.includes("jurnal") ? "mb-0" : 'mb-[75px] md:mb-0'}`}>
          <div className="flex flex-col gap-3">
            <span>© {year} {locale === RU ? 'ООО «UYSOT TEAM»' : '«UYSOT TEAM» MCHJ'} | {layoutLang[String(locale)]['Office_location']}</span>
            <span>{layoutLang[String(locale)]['Пн-Сб. С 8:00 до 18:00']}</span>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-1">
              <GmailIcon data-sentry-element="GmailIcon" data-sentry-source-file="Footer.tsx" />
              <span>info@uysot.uz</span>
            </div>
            <a href="tel:+998781136350" className="flex items-center gap-1">
              <FooterPhoneIcon data-sentry-element="FooterPhoneIcon" data-sentry-source-file="Footer.tsx" />
              <span>+998 78 113 63 50</span>
            </a>
          </div>
        </div>
      </div>
    </footer>;
}
export default Footer;