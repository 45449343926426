import { DEFAULT_LOCATION } from "utils/constants/defaultLocation"
import { LOCATION } from "../../../../utils/constants/queryParamsKeys"
import { rootPaths } from "../../../../utils/constants/rootPaths"

export const menuLinks = () => {

  return [
    {
      label: "Каталог ЖК",
      path: `/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`,
      basePath: `/[${LOCATION}]${rootPaths.RESIDENTIAL_COMPLEXES}`,
    },
    {
      label: "Карта новостроек",
      path: rootPaths.MAP,
      basePath: rootPaths.MAP,
    },
    {
      label: "Квартиры",
      path:`/${DEFAULT_LOCATION}${rootPaths.APARTMENTS}`,
      basePath: `/[${LOCATION}]${rootPaths.APARTMENTS}`,
    },

    {
      label: "Журнал",
      path: rootPaths.MAGAZINES,
      basePath: rootPaths.MAGAZINES,
    },
    {
      label: "Ипотечный калькулятор",
      path: rootPaths.MORTGAGE_CALCULATOR,
      basePath: rootPaths.MORTGAGE_CALCULATOR,
    },
  ]
}
