import React from 'react';
import { Button } from 'antd';
import LogoIcon from 'assets/icons/LogoIcon';
import styles from './Index.module.scss';
import { layoutLang } from 'utils/language/layout';
import { useRouter } from 'next/router';
const AppSuggestion: React.FC = () => {
  const {
    locale,
    push
  } = useRouter();
  const installApp = () => {
    push('/dl');
  };
  return <div className="flex items-center justify-center md:hidden" data-sentry-component="AppSuggestion" data-sentry-source-file="Index.tsx">
      <div className={styles.container}>
        <div className={styles.logoAndInfoContainer}>
          <div className={styles.logo}>
            <LogoIcon data-sentry-element="LogoIcon" data-sentry-source-file="Index.tsx" />
          </div>
          <div className={styles.info}>
            <p>Uysot Marketplace</p>
            <span>{layoutLang[locale || 'ru']?.['Рынок_новых_домов']}</span>
          </div>
        </div>
        <Button className={styles.installAppBtn} type="primary" color="green" onClick={installApp} data-sentry-element="Button" data-sentry-source-file="Index.tsx">
          {layoutLang[locale || 'ru']?.['Скачать']}
        </Button>
      </div>
    </div>;
};
export default AppSuggestion;