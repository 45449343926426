import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { DistrictType } from "../../pages/main-layout/utils/models/districtModel"
import { localStorageKeys } from "../../utils/constants/localStorageKeys"
import { sliceNames } from "../../utils/constants/sliceNames"
import { isBrowser } from "../../utils/helpers/isBrowser"
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation"

type InitialState = {
  visibleLoginModal: boolean
  visibleMobileMenu: boolean
  visibleAddressModal: boolean
  callBackModal: {
    visible: boolean
    type: 'complex' | 'apartment' | 'brand' | 'mortgage' | "story" | null
    id: number | null;
    user_interaction_type?:| "view_picture"
    | "click_phone_button"
    | "click_call_me_button"
    | "view_map"
    | "read_description"
    | "share"
    | "view"
    | "like" | null
    user_interaction_id_type?:"story" | null;
    user_interaction_id?:number | null
  }
  userAddress: (DistrictType & { saved?: boolean } & { cityId?: number }) | null
  isAuth: boolean
  menuIcon: boolean
  isOpen: boolean
  isClose: boolean
  constructionModal: {
    visible: boolean
    id?: number
    data?: {
      images?: {
        small: string
        medium: string
        large: string
      }[]
      name?: string
    }
  }
  activeSlide: number
  openLang: boolean
  openCurrency: boolean
  bonusId: number | null
  complexId: number | null
  brandId: number | null
  openBonus: boolean
  favorites_count: number | null
  anonymousUserId: string | null | undefined
}

const initialState: InitialState = {
  visibleLoginModal: false,
  visibleMobileMenu: false,
  visibleAddressModal: false,
  callBackModal: {
    visible: false,
    type: null,
    id: null,
  },
  userAddress: isBrowser()
    ? JSON.parse(localStorage.getItem(localStorageKeys.ADDRESS) as string) || {
        url_name: DEFAULT_LOCATION,
      }
    : { url_name: DEFAULT_LOCATION },
  isAuth: isBrowser()
    && localStorage.getItem(localStorageKeys.IS_AUTH) === "true" ? true
    : false,
  menuIcon: false,
  isOpen: true,
  isClose: true,
  constructionModal: {
    id: -1,
    visible: false,
  },
  activeSlide: 0,
  openLang: false,
  openCurrency: false,
  bonusId: null,
  complexId: null,
  openBonus: false,
  favorites_count: 0,
  brandId: null,
  anonymousUserId: null
}

const userReducer = createSlice({
  name: sliceNames.SMTH,
  initialState,
  reducers: {
    setVisibleLoginModal: (state, action: PayloadAction<boolean>) => {
      state.visibleLoginModal = action.payload
    },
    setVisibleMobileMenu: (state, action: PayloadAction<boolean>) => {
      state.visibleMobileMenu = action.payload
    },
    toggleVisibleMobileMenu: (state) => {
      state.visibleMobileMenu = !state.visibleMobileMenu
    },
    setVisibleAddressModal: (state, action: PayloadAction<boolean>) => {
      state.visibleAddressModal = action.payload
    },
    setCallBackModal: (
      state,
      action: PayloadAction<{
        visible: boolean
        type: 'complex' | 'apartment' | 'brand' | "story" | null
        id: number | null
        user_interaction_type?:| "view_picture"
          | "click_phone_button"
          | "click_call_me_button"
          | "view_map"
          | "read_description"
          | "share"
          | "view"
          | "like" | null
        user_interaction_id_type?:"story" | null;
        user_interaction_id?:number | null
      }>
    ) => {
      state.callBackModal = action.payload
    },
    setUserAddress: (
      state,
      action: PayloadAction<
        (DistrictType & { saved?: boolean } & { cityId?: number }) | null
      >
    ) => {
      state.userAddress = action.payload
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
      if (action.payload) {
        localStorage.setItem(localStorageKeys.IS_AUTH, "true")
      } else {
        localStorage.removeItem(localStorageKeys.IS_AUTH)
      }
      state.isAuth = action.payload
    },
    setChangeMenu: (state, action: PayloadAction<boolean>) => {
      state.menuIcon = action.payload
    },
    setIsClose: (state, action: PayloadAction<boolean>) => {
      state.isClose = action.payload
    },
    setConstructionModal: (
      state,
      action: PayloadAction<{
        visible: boolean
        id?: number
        data?: {
          images?: {
            small: string
            medium: string
            large: string
          }[]
          name?: string
        }
      }>
    ) => {
      state.constructionModal = action.payload
    },
    setActiveSlide: (state, action: PayloadAction<number>) => {
      state.activeSlide = action.payload
    },
    setOpenLang: (state, action: PayloadAction<boolean>) => {
      state.openLang = action.payload
    },
    setOpenCurrency: (state, action: PayloadAction<boolean>) => {
      state.openCurrency = action.payload
    },
    setBonusId: (state, action: PayloadAction<{bonusId: number | null, complexId: number | null}>) => {
      state.bonusId = action.payload.bonusId
      state.complexId = action.payload.complexId
    },
    setOpenBonus: (state, action: PayloadAction<boolean>) => {
      state.openBonus = action.payload
    },
    setFavoritesCount: (state, action: PayloadAction<number>) => {
      state.favorites_count = state.favorites_count! + action.payload
    },
    setOpenCallBackMortgage: (state, action: PayloadAction<{visible: boolean,  type: 'complex' | 'apartment' | 'brand' | 'mortgage' | null}>) => {
      state.callBackModal.visible = action.payload.visible
      state.callBackModal.type = action.payload.type
    },
    setBrandId: (state, action: PayloadAction<number | null>) => {
      state.brandId = action.payload
    },
    setAnonymousUserId: (state, action: PayloadAction<string | null | undefined>) => {
      state.anonymousUserId = action.payload
    }
  },
})

export default userReducer.reducer
export const userReducerActions = userReducer.actions
