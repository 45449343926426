import React from 'react';
const ComplexHeartIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return <svg {...props} width={props?.width || '28'} height={props?.height || '28'} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ComplexHeartIcon" data-sentry-source-file="ComplexHeartIcon.tsx">
      <g filter="url(#filter0_b_13247_23829)" data-sentry-element="g" data-sentry-source-file="ComplexHeartIcon.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9917 5.99178C11.6591 3.2648 7.76938 2.53125 4.84682 5.02835C1.92426 7.52545 1.5128 11.7005 3.80791 14.6538C5.71613 17.1093 11.4911 22.2881 13.3838 23.9643C13.5955 24.1518 13.7014 24.2456 13.8249 24.2824C13.9327 24.3146 14.0507 24.3146 14.1584 24.2824C14.2819 24.2456 14.3878 24.1518 14.5996 23.9643C16.4923 22.2881 22.2672 17.1093 24.1755 14.6538C26.4706 11.7005 26.1093 7.49918 23.1365 5.02835C20.1638 2.55752 16.3243 3.2648 13.9917 5.99178Z" fill="black" fillOpacity="0.3" data-sentry-element="path" data-sentry-source-file="ComplexHeartIcon.tsx" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9917 5.99178C11.6591 3.2648 7.76938 2.53125 4.84682 5.02835C1.92426 7.52545 1.5128 11.7005 3.80791 14.6538C5.71613 17.1093 11.4911 22.2881 13.3838 23.9643C13.5955 24.1518 13.7014 24.2456 13.8249 24.2824C13.9327 24.3146 14.0507 24.3146 14.1584 24.2824C14.2819 24.2456 14.3878 24.1518 14.5996 23.9643C16.4923 22.2881 22.2672 17.1093 24.1755 14.6538C26.4706 11.7005 26.1093 7.49918 23.1365 5.02835C20.1638 2.55752 16.3243 3.2648 13.9917 5.99178Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ComplexHeartIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="ComplexHeartIcon.tsx">
        <filter id="filter0_b_13247_23829" x="-2.66699" y="-1.50183" width="33.333" height="30.8083" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="ComplexHeartIcon.tsx">
          <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="ComplexHeartIcon.tsx" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" data-sentry-element="feGaussianBlur" data-sentry-source-file="ComplexHeartIcon.tsx" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_13247_23829" data-sentry-element="feComposite" data-sentry-source-file="ComplexHeartIcon.tsx" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_13247_23829" result="shape" data-sentry-element="feBlend" data-sentry-source-file="ComplexHeartIcon.tsx" />
        </filter>
      </defs>
    </svg>;
};
export default ComplexHeartIcon;