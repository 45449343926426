import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { localStorageKeys } from "../../../utils/constants/localStorageKeys"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { NewApartmentCardModel } from "../../../utils/models/apartmentModel"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/apis"
import { queryKeys } from "../utils/constants/queryKeys"
import {
  CityType,
  DistrictsType,
  MainRegionsModel,
  Metros,
} from "../utils/models/districtModel"
import { isBrowser } from "../../../utils/helpers/isBrowser"
import { fetchFavorites } from "pages/main-layout/services/queryFunctions"
import { useAppDispatch } from "hooks/reduxHooks"
import { userReducerActions } from "store/reducers/userReducer"

// get city
export function useGetRegionCity() {
  return useQuery<CityType[], ErrorRes>(
    [queryKeys.CITY],
    async () => {
      const res = await $api.get(endpoints.CITY)
      return res.data.data
    },
    {
      onError: errorHandler,
      staleTime: Infinity,
    }
  )
}

export function useGetRegionDistricts(city_url_name: string, step?: number) {
  const url = city_url_name ? `${endpoints.REGION_DISTRICTS}?city_url_name=${city_url_name}` : endpoints.REGION_DISTRICTS
  return useQuery<DistrictsType[], ErrorRes>(
    [queryKeys.REGION_DISTRICTS, city_url_name],
    async () => {
      const res = await $api.get(url)
      return res.data.data
    },
    {
      onError: errorHandler,
      enabled: !!city_url_name && step === 1,
      staleTime: Infinity
    }
  )
}

// check auth
export function useCheckAuth() {
  const hasToken = isBrowser() ? localStorage.getItem(localStorageKeys.TOKEN) : ""
  const dispatch = useAppDispatch()
  const { setFavoritesCount, setAnonymousUserId } = userReducerActions

  const anonymous_user_id = isBrowser()
    ? localStorage.getItem("anonymous_user_id")
    : ""
    
  return useQuery(
    [queryKeys.CHECK_AUTH, anonymous_user_id],
    async () => {
      const res = await $api.get(
        `${endpoints.AUTH}?anonymous_user_id=${anonymous_user_id}`
      )
      return res.data
    },
    {
      onSuccess: (res) => {
        if (res.data.anonymous_user_id) {
          localStorage.setItem(localStorageKeys.IS_AUTH, "false")
          localStorage.setItem("anonymous_user_id", res.data.anonymous_user_id)
          dispatch(setAnonymousUserId(res.data.anonymous_user_id))
        } else {
          localStorage.setItem(localStorageKeys.IS_AUTH, "true")
          // localStorage.setItem(localStorageKeys.PHONE, res?.data?.phone)
        }
        if (res?.data?.favorites_count) {
          dispatch(setFavoritesCount(res?.data?.favorites_count))
        }
      },
      staleTime: Infinity,
      enabled: !(anonymous_user_id === "null" || anonymous_user_id?.length === 36 || anonymous_user_id?.length === 128) && !hasToken,
    }
  )
}

// get favourites
export function useGetFavourites() {
  return useQuery<{
    data: {
      complexes: NewResidentalComplexModel[]
      apartments: NewApartmentCardModel[]
    }
  }>({
    queryKey: [queryKeys.FAVOURITES],
    queryFn: fetchFavorites,
  })
}
export function useGetMainRegions() {
  return useQuery<MainRegionsModel[]>({
    queryKey: [queryKeys.MAIN_REGIONS],
    queryFn: async () => {
      const res = await $api.get(`/city/select`)
      return res.data.data
    },
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export function useGetMetros() {
  return useQuery<Metros[]>({
    queryKey: [queryKeys.METROS],
    queryFn: async () => {
      const res = await $api.get("/metro-line/select")
      return res.data.data
    },
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })
}
