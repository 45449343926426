import React, { ReactNode } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styles from "./expo.module.scss";
import { Oswald } from "next/font/google";
const oswald = Oswald({
  subsets: ["latin", "cyrillic"],
  weight: ["400", "500", "600"]
});
type Props = {
  children: ReactNode;
};
const Expo = ({
  children
}: Props) => {
  return <>
      <div className={`${styles.container} ${oswald.className}`}>
        <Header data-sentry-element="Header" data-sentry-source-file="Expo.tsx" />
        {children}
        <Footer data-sentry-element="Footer" data-sentry-source-file="Expo.tsx" />
      </div>
    </>;
};
export default Expo;