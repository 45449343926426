import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React from 'react';
import ReactGA from 'react-ga4';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { userReducerActions } from '../../../store/reducers/userReducer';
import { gaActions, gaCategories } from '../../../utils/constants/ga';
import { RU, UZ } from '../../../utils/constants/locales';
import { rootPaths } from '../../../utils/constants/rootPaths';
import { isEmptyArr } from '../../../utils/helpers/isEmptyArr';
import { makeUrl } from '../../../utils/helpers/makeUrl';
import { NewResidentalComplexModel } from '../../../utils/models/residentialComplexModel';
import CommonCardTop from '../top/CommonCardTop';
import styles from '../commonCard.module.scss';
import { useRouter } from 'next/router';
import PhoneIcon from '../../../assets/icons/PhoneIcon';
import Placements from '../../../pages/detailed-residential-complex/components/placements/Placements';
import { layoutLang } from '../../../utils/language/layout';
import BadgeIcon from 'assets/icons/BadgeIcon';
import { cx } from 'utils/cx';
import { isEmptyObject } from 'utils/helpers/isEmptyObject';
import CustomLink from 'common/custom-link';
import ComplexApartments from '../complex-apartments/ComplexApartments';
import { useCreateUserInteractions } from 'pages/detailed-magazine/service/mutations';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import ComplexAvailability from '../complex-availability/complex-availability';
dayjs.extend(advancedFormat);
type Props = {
  data: NewResidentalComplexModel;
  isMap?: boolean;
};
const ComplexCard: React.FC<Props> = ({
  data,
  isMap
}) => {
  const dispatch = useAppDispatch();
  const {
    locale,
    pathname
  } = useRouter();
  const {
    setCallBackModal
  } = userReducerActions;
  const {
    clearFilters,
    setWindowPosition
  } = favouritesReducerActions;
  const userInteraction = useCreateUserInteractions();
  const handleUserInteraction = () => {
    dispatch(setWindowPosition({
      positionY: 0,
      activeKey: undefined
    }));
    dispatch(clearFilters());
    userInteraction.mutate({
      interaction_type: 'view',
      complex_id: data?.id
    });
  };

  // coll back
  const handleCallBack = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setCallBackModal({
      visible: true,
      type: 'complex',
      id: data?.id
    }));
    ReactGA.event({
      action: gaActions.CALL_ME,
      category: gaCategories.PHONE_NUMBER
    });
  };
  return <div className="relative w-full" data-sentry-component="ComplexCard" data-sentry-source-file="ComplexCard.tsx">
      <CustomLink href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}/${makeUrl(data?.slug || data?.name)}-${data?.id}`} target={isMap ? '_blank' : '_self'} onClick={handleUserInteraction} data-sentry-element="CustomLink" data-sentry-source-file="ComplexCard.tsx">
        <div className={`${styles.complex_card} ${pathname.includes('novostroyki') ? 'h-auto md:h-[516px]' : 'h-[492px] md:h-[516px]'}`}>
          <CommonCardTop images={data?.image} classes={data?.class} payments={data?.payment_methods} is_top={data?.is_top} alt={locale === UZ ? `${data?.name || data?.name} turar-joy majmuasi | ${layoutLang[String(locale)]['Каталог ЖК']} ${data?.district?.city?.name?.uz ? `${data?.district?.city?.name?.uz}da` : ''} | Uysot` : `${layoutLang[String(locale)]['TJM']} ${data?.name || data?.name} | ${layoutLang[String(locale)]['Каталог ЖК']} в ${data?.district?.city?.name?.ru ?? ''} | Uysot`} data={data} type="complex" data-sentry-element="CommonCardTop" data-sentry-source-file="ComplexCard.tsx" />
          <div className={styles.complex_card_bottom}>
            <ComplexAvailability data={data} data-sentry-element="ComplexAvailability" data-sentry-source-file="ComplexCard.tsx" />

            <div className="flex flex-col gap-3">
              <div className={cx(isEmptyArr(data?.payment_methods), 'flex flex-col gap-1.5')}>
                <div className={cx(styles.name)}>
                  <div className={cx(isEmptyArr(data?.payment_methods))}>
                    <h3>
                      {`${locale === RU ? layoutLang[String(locale)]['ЖК'] : ''} "${data?.name}" ${locale === UZ ? layoutLang[String(locale)]['ЖК'] : ''}`}
                    </h3>
                  </div>
                </div>
                <p className={styles.address}>
                  {locale === RU ? data?.district?.city?.name?.ru : data?.district?.city?.name?.uz}
                  {', '}
                  {locale === RU ? data?.district?.name?.ru : data?.district?.name?.uz}
                </p>
                {data?.nearest_place && !isEmptyObject(data?.nearest_place) && <Placements type="complex" data={data?.nearest_place} />}
              </div>
            </div>
            <div className={cx('mt-3 flex flex-col gap-3 md:mt-0', data?.nearest_place && !isEmptyObject(data?.nearest_place) && 'mt-4')}>
              <div className="mb-6 flex flex-col justify-between gap-3">
                <ComplexApartments apartments={data?.apartment_statistics} data-sentry-element="ComplexApartments" data-sentry-source-file="ComplexCard.tsx" />
              </div>
              <div className={styles.call} onClick={handleCallBack}>
                <PhoneIcon data-sentry-element="PhoneIcon" data-sentry-source-file="ComplexCard.tsx" />
                <span>{layoutLang[String(locale)]['Перезвоните мне']}</span>
              </div>
            </div>
          </div>
        </div>
      </CustomLink>
      <CustomLink className="absolute bottom-14 left-4 mb-3 flex min-w-[80%] items-center gap-2" href={`${rootPaths.BUILDERS}/${makeUrl(data?.brand?.slug ? data?.brand?.slug : data?.brand?.name)}`} data-sentry-element="CustomLink" data-sentry-source-file="ComplexCard.tsx">
        <div className={`${styles.builder} !mb-0 pr-2 !text-[#1570EF]`}>
          {data?.brand ? data?.brand?.name : data?.builder?.name}
        </div>
        {data?.brand?.badge ? <Tooltip overlay={layoutLang[String(locale)]['badge_text']}>
            <span>
              <BadgeIcon />
            </span>
          </Tooltip> : ''}
      </CustomLink>
    </div>;
};
export default ComplexCard;