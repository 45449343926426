import { useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import React from 'react';
import { USD, UZ } from 'utils/constants/locales';
import { calcUsdValue } from 'utils/helpers/calcUsdValue';
import { filterPriceSeparator } from 'utils/helpers/filterPriceSeparator';
import { layoutLang } from 'utils/language/layout';
import { ApartmentStatistict } from 'utils/models/residentialComplexModel';
interface IProps {
  apartments: ApartmentStatistict[] | undefined;
}
const ComplexApartments: React.FC<IProps> = ({
  apartments
}) => {
  const {
    locale
  } = useRouter();
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const usdValue = useAppSelector(state => state.localeReducer?.usdValue);
  const finalPrice = (price: number) => currency === USD ? filterPriceSeparator(Math.ceil(calcUsdValue(price, usdValue, currency)), locale) : filterPriceSeparator(price, locale);
  return <div className='flex flex-col gap-3 h-[75px]' data-sentry-component="ComplexApartments" data-sentry-source-file="ComplexApartments.tsx">
      {(apartments && apartments?.length > 3 ? apartments?.slice(0, 3) : apartments)?.map((item, index) => <div key={index} className='flex items-end justify-between h-[17px] gap-2'>
          <div className='flex items-end gap-1.5'>
            <span className='text-[#1D2939] font-semibold text-sm w-max'>{`${locale === UZ ? `${item?.rooms_count}${index === 2 && apartments && apartments?.length > 3 ? "+" : ""} xona` : `${item?.rooms_count}${index === 2 && apartments && apartments?.length > 3 ? "+" : ""} комн.`}`}</span>
            <span className='text-[#475467] font-medium text-[13px]'>{`${locale === UZ ? `${item?.min_area} m² dan` : `от ${item?.min_area} м²`}`}</span>
          </div>
          <div className={`border border-dashed border-[#98A2B3] flex-1 mb-1`} />
          {item?.min_total_price ? <span className='text-[#475467] font-medium text-[13px]'>{locale === UZ ? `${finalPrice(item?.min_total_price)} dan` : `от ${finalPrice(item?.min_total_price)}`}</span> : <span className='text-[#475467] text-[13px] font-medium'>{layoutLang[String(locale)]["Договорная"]}</span>}
        </div>)}
    </div>;
};
export default ComplexApartments;