import { isEmptyObject } from "../../../utils/helpers/isEmptyObject";
import { LOCATION, SIZE } from "../../../utils/constants/queryParamsKeys";
import queryString, { stringify } from "query-string";
import { ParsedUrlQuery } from "querystring";
import $api from "../../../services/RequestService";
import { isNumberOdd } from "utils/helpers/isNumberOdd";
import { EXCLUDE_URL } from "pages/residential-complexes/utils/constants/excludeUrl";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";

// apartments
export const fetchApartments = (query?: ParsedUrlQuery, complex_id?: number) => {
  let url = `/apartment/view`;

  const queryCopy: ParsedUrlQuery = query?.[LOCATION] === DEFAULT_LOCATION ? {
    page: "1",
    [Array.isArray(query?.metro_line_ids) ? "metro_line_ids" : "metro_line_ids[]"]: query?.metro_line_ids,
    [Array.isArray(query?.nearest_place_ids) ? "nearest_place_ids" : "nearest_place_ids[]"]: query?.nearest_place_ids,
    [Array.isArray(query?.complex_ids) ? "complex_ids" : "complex_ids[]"]: query?.complex_ids,
    ...query
  } :  {
    page: "1",
    city_url_name: query ? query[LOCATION] : undefined,
    [Array.isArray(query?.metro_line_ids) ? "metro_line_ids" : "metro_line_ids[]"]: query?.metro_line_ids,
    [Array.isArray(query?.nearest_place_ids) ? "nearest_place_ids" : "nearest_place_ids[]"]: query?.nearest_place_ids,
    [Array.isArray(query?.complex_ids) ? "complex_ids" : "complex_ids[]"]: query?.complex_ids,
    ...query
  };

  if (complex_id) {
    if (!isEmptyObject(queryCopy)) {
      url += `?${stringify(
        {
          ...queryCopy,
          [SIZE]: !queryCopy?.page || isNumberOdd(Number(queryCopy?.page)) ? 14 : 15,
          complex_id: complex_id
        },
        { arrayFormat: "comma" }
      )}`;
    } else {
      url += `?${stringify({ complex_id: complex_id }, { arrayFormat: "comma" })}`;
    }
    url = queryString.exclude(url, [EXCLUDE_URL.location]);
  } else if (!isEmptyObject(queryCopy)) {
    url += `?${stringify(
      {
        ...queryCopy,
        [SIZE]: 14
      },
      { arrayFormat: "bracket" }
    )}`;
    url = queryString.exclude(url, [EXCLUDE_URL.location]);
  }

  return {
    fetch: async () => {
      const res = await $api.get(url);
      return res.data.data;
    },
    url
  };
};

//payment-methods
export const fetchNewPaymentMethods = async () => {
  const res = await $api.get(`/payment-method/select`);
  return res.data.data;
};
